import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpParams,
	HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable, throwError } from "rxjs";

import { catchError, switchMap } from "rxjs/operators";
import { AppConfiguration } from "src/app/app.configuration";
import { MicroserviceTokenResponse } from "src/app/shared/interfaces/microservices";
import { MicroserviceService } from "src/app/shared/services/microservice.service";
import { UserManagerService } from "../auth/user-manager.service";

@Injectable()
export class RequestMicroserviceInterceptor implements HttpInterceptor {
	routesToIgnore = [];
	isRefreshing = false;
	private refreshTokenSubject: BehaviorSubject<MicroserviceTokenResponse | null> = new BehaviorSubject<MicroserviceTokenResponse | null>(null);

	constructor(
		private config: AppConfiguration,
		private microservice: MicroserviceService,
		private userManagerService: UserManagerService,
	) {
		const apiURL = this.config.getConfig("API_URL_MS");
		this.routesToIgnore = [`${apiURL}/Token`, `${apiURL}/Refresh`];
	}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const apiURL = this.config.getConfig("API_URL_MS");
		if (req.url.includes(apiURL)) {
			if (this.routesToIgnore.includes(req.url)) {

				const modReq = this.addHeadersData(req);

				return next.handle(modReq);
			}
			// Obtenemos el token
			// Importante: modificamos de forma inmutable, haciendo el clonado de la petición
			const modReq = this.addCommonData(req);
			// Pasamos al siguiente interceptor de la cadena la petición modificada
			//return next.handle(modReq);
			return next.handle(modReq).pipe(
				catchError((error: HttpErrorResponse) => {
					if (error.status === 401) {
						return this.handle401Error(req, next);
					}
					return throwError(error);
				})
			);
		} else {
			return next.handle(req);
		}
	}

	addHeadersData(req: HttpRequest<any>): HttpRequest<any> {
		const claveEmpresa = this.config.getConfig("chClaveEmpresa") || "";
		
		if (claveEmpresa === 'TICKAMORE_UNITARIO') {
			const modReq = req.clone({
				setHeaders: {
					"Content-Type": "application/json",
					"Cache-Control": "no-cache",
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
				},
			});
			return modReq;
		}

		const modReq = req.clone({
			setHeaders: {
				"Content-Type": "application/json",
				"Cache-Control": "no-cache",
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': 'true',
				'Access-Control-Allow-Headers': 'Content-Type',
				'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
				"IAC-Connection-String": claveEmpresa,
			},
		});

		return modReq;
	}

	addCommonData(req: HttpRequest<any>): HttpRequest<any> {
		let conexionId = localStorage.getItem("conexionId") || "";
		const token = localStorage.getItem("tokenMicroservice") || "";
		const claveEmpresa = this.config.getConfig("chClaveEmpresa") || "";

		if (conexionId) {
			conexionId = conexionId.replace(/[^0-9]/g, '');
		}

		if (req.method === "GET" || req.method === "DELETE") {


			let keysParams = req.params.keys();

			let newParams = new HttpParams();

			if (keysParams.length > 0) {
				keysParams.forEach((key) => {
					newParams = newParams.set(key, req.params.get(key));
				});
			}

			newParams = newParams.set("ConexionId", conexionId);

			let modReq;

			if (claveEmpresa === 'TICKAMORE_UNITARIO') {
				modReq = req.clone({
					setHeaders: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					params: newParams,
				});
			} else {
				modReq = req.clone({
					setHeaders: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
						'IAC-Connection-String': claveEmpresa,
					},
					params: newParams,
				});
			}

			return modReq;
		}

		if (req.body) {
			let body = req.body;

			body["ConexionId"] = conexionId;

			let modReq;

			if (claveEmpresa === 'TICKAMORE_UNITARIO') {
				modReq = req.clone({
					setHeaders: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: body,
				});
			} else {
				modReq = req.clone({
					setHeaders: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
						'IAC-Connection-String': claveEmpresa,
					},
					body: body,
				});
			}

			return modReq;
		}

		return req;
	}

	private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this.refreshTokenSubject.next(null);

			return this.microservice.getTokenMicroservice("", "").pipe(
				switchMap((token: MicroserviceTokenResponse) => {
					this.isRefreshing = false;
					this.microservice.loginMicroservice(token);
					this.refreshTokenSubject.next(token);
					return next.handle(this.addCommonData(req));
				}),
				catchError((error) => {
					this.isRefreshing = false;
					this.userManagerService.logout();
					return throwError(() => error);
				}));
		} else {
			return this.refreshTokenSubject.pipe(
				switchMap((token) => {
					if (token) {
						return next.handle(this.addCommonData(req));
					}

					return throwError(() => new Error('No token available'));
				})
			);
		}
  	}
}
