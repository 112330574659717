import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../user.model';
import { map, flatMap } from 'rxjs/operators';
import { AppConfiguration } from 'src/app/app.configuration';
import { TurnoService } from './turno.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorToast } from '../toast/error.toast';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserManagerService {
  private currentUserSubject: BehaviorSubject<User>;
  currentUser: Observable<User>;

  constructor(
    private http: HttpClient,
    private config: AppConfiguration,
    private turnoService: TurnoService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public setCurrentUser(user: User) {
    this.currentUserSubject.next(user);
  }


  updateUsuarioConectado(id: string, conectado: boolean): Observable<any> {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      UsuarioId: id,
      Conectado: conectado ? 1 : 0
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/VentaTaquilla/UpdUsuarioConectado`,
      body
    );
  }

  elevarUsuario(nombre: string, contrasena: string) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      NombreUsuario: nombre,
      ClaveUsuario: contrasena,
      AplicacionCod: 'PVKORE'
    };
    return this.http.post(`${this.config.getConfig('API_URL')}api/Usuarios`, body).pipe(
      map((res: any) => {
        const user = <User>res.DatosResult;
        return user;
      })
    );
  }

  logout() {
    this.updateUsuarioConectado(this.currentUserValue.Id, false).subscribe(() => {
      this.currentUserSubject.next(null);
      this.turnoService.setCambioValue(null);
      this.turnoService.setTurnoValue(null);
      this.turnoService.getTPVenUso();
      localStorage.removeItem("tokenMicroservice");
      localStorage.removeItem("microserviceData");
      this.router.navigate(['login']);
    });
  }

  cambiarPassword(nombre: string, contrasena: string, nuevaContrasena: string){
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      Nombre: nombre,
      Clave: contrasena,
      NuevaClave: nuevaContrasena
    };
    return this.http.post(`${this.config.getConfig('API_URL')}api/Customan/ActualizarContrasenaCustomanUsuarios`, body).pipe(
      map((res: any) => {
        if(res && res.Mensajes && res.Mensajes[0] && res.Mensajes[0].CodigoMensaje && res.Mensajes[0].CodigoMensaje != '0')
        {
          this.toastr.error('', res.Mensajes[0].DescripcionMensaje, {
          // this.toastr.error('', this.translate.instant(res.Mensajes.CodigoMensaje), {
            toastComponent: ErrorToast
          });
          return false;
        }if (!res)
        {
          return false;
        }
        return true;
      })
    );

  }
  cambiarPasswordById(pkId, usuario, contrasena,  nuevaContrasena: string){
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      pkId: Number.parseInt(pkId, 10),
      funcion: 'C',
      Nombre: usuario,
      Clave: contrasena,
      NuevaClave: nuevaContrasena
    };
    return this.http.post(`${this.config.getConfig('API_URL')}api/Customan/ActualizarContrasenaCustomanUsuarios`, body).pipe(
      map((res: any) => {
        if(res && res.Mensajes && res.Mensajes[0] && res.Mensajes[0].CodigoMensaje && res.Mensajes[0].CodigoMensaje != '0')
        {
          this.toastr.error('', res.Mensajes[0].DescripcionMensaje, {
          // this.toastr.error('', this.translate.instant(res.Mensajes.CodigoMensaje), {
            toastComponent: ErrorToast
          });
          return false;
        }if (!res)
        {
          return false;
        }
        return true;
      })
    );

  }
}
