import { Component, OnDestroy, OnInit } from '@angular/core';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { distinctUntilChanged, take, takeWhile } from 'rxjs/operators';
import { TranslateService } from 'src/app/shared/services/translate.service';
import {
  Carrito,
  EntradasService,
  StepperNavigationService,
  VentaService,
} from '../../../venta';
import * as moment from 'moment';
import { PagerService } from '../../../../shared/services/pager.service';
import { Entrada } from '../../../venta/models/entrada.model';
import { ModalService } from 'src/app/shared/services/modal.service';
import { VisorService } from 'src/app/shared/services/visor.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfigurationService } from 'src/app/core';
import { NumpadService } from 'src/app/shared/services/numpad.service';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { Router } from '@angular/router';
import { PermisosService } from 'src/app/core/security/permisos.service';
import { UserManagerService } from 'src/app/core/auth/user-manager.service';
import { RestauracionService } from '../../services/restauracion.service.service';
import { EntradaRestauracion } from '../../models/entrada.microservicio.model';

interface Categoria {
  pkId: string;
  nombre: string;
  Descripcion: string;
  TipoProducto: string;
  expandido: boolean;
}

@Component({
  selector: 'restauracion-selector-mas-entradas',
  templateUrl: './selector-mas-entradas.component.html',
  styleUrls: ['./selector-mas-entradas.component.scss'],
})
export class RestauracionSelectorMasEntradasComponent
  implements OnInit, OnDestroy
{
  categorias: Categoria[];
  config = PERFECT_SCROLLBAR_CONFIG;
  categoriaActiva: Categoria;
  subcategoriasSeleccionadas: Categoria[];
  entradas: Entrada[];
  ultimaSubcategoriaCargada: Categoria[] = []; // Agregada aquí

  tiposProducto: [{ Cod: string; Desc: string }] = [{ Cod: null, Desc: null }];
  gruposAsociados: [{ Cod: string; Desc: string }] = [
    { Cod: null, Desc: null },
  ];
  tiposNivel: [{ Cod: string; Desc: string }] = [{ Cod: null, Desc: null }];

  filtroTiposProducto: string;
  filtroGruposAsociados: string;
  filtroTiposNivel: string;

  entradaSeleccionada: Entrada;

  pager: any = {};
  pagedItems: any[];

  busquedaForm: FormGroup;
  filtrosForm: FormGroup;
  filtrosTextForm: FormGroup;
  carrito: Carrito;
  idioma = 'es-ES';

  myFormSub: Subscription;

  fecha: any;
  cliente: any;

  referrer: string;

  private alive = true;
  divisaSimboloDecimal: string;
  divisaSimbSeparacionMiles: string;
  divisaSimbolo: string;
  divisaDecimales: number;
  divisaPosicion: string;
  esGranDivisa: boolean;
  divisaSimboloDerecha: string;

  filtradoPorProducto = '';
  filtradoPorGrupoAso = '';
  filtradoPorNivel = '';
  filtradoPorNombre = '';
  verSelectores = false;

  private datosInicioSub: Subscription;
  private carritoSub: Subscription;
  private fechaSeleccionadaSub: Subscription;
  private clienteSeleccionadoSub: Subscription;
  private recalculaCarritoSub: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    private ventaService: VentaService,
    private configurationService: ConfigurationService,
    private entradaService: EntradasService,
    private numpadService: NumpadService,
    private formControlService: FormControlService,
    private modalService: ModalService,
    private pagerService: PagerService,
    private formBuilder: FormBuilder,
    private visor: VisorService,
    private router: Router,
    private permisosService: PermisosService,
    private user: UserManagerService,
    private navigationService: StepperNavigationService,
    private restauracionService: RestauracionService
  ) {
    this.busquedaForm = this.formBuilder.group({
      cliente: '',
      fecha: '',
    });
    this.filtrosForm = this.formBuilder.group({
      tipoproducto: '',
      rangoedad: '',
      tiponivel: '',
    });
    this.filtrosTextForm = this.formBuilder.group({
      busqueda: '',
    });
  }
  ngOnInit() {
    if (
      this.ventaService.carritoValue.entradas.length > 0 &&
      this.categorias === undefined &&
      this.entradas === undefined &&
      this.ventaService.irPago === true
    ) {
      this.navigationService.continuarVenta.next(true);
      this.navigationService.next();
      this.ventaService.irPago = false;
    }

    this.onChanges();
    this.divisaSimbSeparacionMiles =
      this.configurationService.divisasValue[0].SimbSeparacionMiles;
    this.divisaSimboloDecimal =
      this.configurationService.divisasValue[0].SimboloDecimal;
    this.divisaSimbolo = this.configurationService.divisasValue[0].simbolo;
    this.divisaDecimales = Number(
      this.configurationService.divisasValue[0].NumeroDecimales
    );
    this.divisaPosicion =
      this.configurationService.divisasValue[0].PosicionDivisa;
    this.divisaSimboloDerecha =
      this.configurationService.divisasValue[0].SimboloDerecha;
    this.datosInicioSub = this.configurationService.datosInicio.subscribe(
      (conf) => (this.idioma = conf.Idioma)
    );
    this.carritoSub = this.ventaService.carrito.subscribe(
      (carrito) => (this.carrito = carrito)
    );
    this.fechaSeleccionadaSub = this.ventaService.fechaSeleccionada
      .pipe(takeWhile(() => this.alive))
      .subscribe((fecha) => {
        if (fecha === '') {
          this.ventaService.modificarTarifasFecha(
            moment().format('YYYY/MM/DD')
          );
          this.fecha = '';
          this.busquedaForm.get('fecha').setValue('');
        } else {
          this.ventaService.modificarTarifasFecha(
            moment(fecha).format('YYYY/MM/DD')
          );
          this.fecha = fecha;
          this.busquedaForm.controls['fecha'].patchValue(
            moment(this.fecha).locale(this.idioma).format('L')
          );
        }
        if (!this.categorias || this.categorias.length == 0) {
          this.onChanges();
        }
      });
    this.clienteSeleccionadoSub = this.ventaService.clienteSeleccionado
      .pipe(takeWhile(() => this.alive))
      .subscribe((cliente) => {
        this.cliente = cliente;
        this.busquedaForm.controls['cliente'].patchValue(
          cliente ? cliente.NombreCliente : ''
        );
      });
    this.subscriptions.push(this.datosInicioSub);
    this.subscriptions.push(this.fechaSeleccionadaSub);
    this.subscriptions.push(this.carritoSub);
    this.subscriptions.push(this.clienteSeleccionadoSub);
    // Cargar Lista de Filtros Posibles
    this.entradaService
      .getFiltroTiposProducto()
      .pipe(takeWhile(() => this.alive))
      .subscribe((filtro: any) => {
        if (
          filtro != null &&
          filtro.DatosResult != null &&
          filtro.DatosResult.ListadoTiposProductos != null
        ) {
          this.tiposProducto = filtro.DatosResult.ListadoTiposProductos.map(
            (x: any) => ({
              Cod: x.Codigo,
              Desc: x.Descripcion,
            })
          );
        } else {
          this.tiposProducto = [{ Cod: null, Desc: null }];
        }
      });
    this.entradaService
      .getFiltroGruposAsociado()
      .pipe(takeWhile(() => this.alive))
      .subscribe((filtro: any) => {
        if (
          filtro != null &&
          filtro.DatosResult != null &&
          filtro.DatosResult.ListadoGruposAsociados != null
        ) {
          this.gruposAsociados = filtro.DatosResult.ListadoGruposAsociados.map(
            (x: any) => ({
              Cod: x.Codigo,
              Desc: x.Descripcion,
            })
          );
        } else {
          this.gruposAsociados = [{ Cod: null, Desc: null }];
        }
      });
    this.entradaService
      .getFiltroTiposNiveles()
      .pipe(takeWhile(() => this.alive))
      .subscribe((filtro: any) => {
        if (
          filtro != null &&
          filtro.DatosResult != null &&
          filtro.DatosResult.ListadoTiposNiveles != null
        ) {
          this.tiposNivel = filtro.DatosResult.ListadoTiposNiveles.map(
            (x: any) => ({
              Cod: x.Codigo,
              Desc: x.Descripcion,
            })
          );
        } else {
          this.tiposNivel = [{ Cod: null, Desc: null }];
        }
      });
    this.visor.setToBlank();
  }

  ngOnDestroy() {
    this.alive = false;
    if (this.myFormSub) {
      this.myFormSub.unsubscribe();
    }
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  
  private obtenerPadreDePrimeraSubcategoria(categoria: any): any {
    let padre = null;
    while (categoria.categorias && categoria.categorias.length > 0) {
      padre = categoria; // Guarda el padre antes de descender
      categoria = categoria.categorias[0]; // Baja a la siguiente subcategoría
    }
    return padre;
  }
  

  onChanges() {
    this.restauracionService
      .getCategorias('pkId', 'ASC', ['TipoElemento'], ['3'])
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res && res.length > 0) {
          this.categorias = this.inicializarCategorias(res);

          // Seleccionar la última categoría en lugar de la primera
          this.categoriaActiva = this.categorias[0];
          
          // Obtener el padre de la primera subcategoría
          const subCategoria = this.obtenerPadreDePrimeraSubcategoria(this.categoriaActiva);
          
          // Buscar el padre dentro de las categorías iniciales usando el pkId
          const categoriaPadre = this.categorias.find(cat => cat.pkId === subCategoria.pkId);
          
          
          // Asignar las subcategorías seleccionadas
          this.categoriaActiva = subCategoria.categorias[0];
          this.categoriaActiva.expandido = true;
          this.subcategoriasSeleccionadas = subCategoria.categorias;
          this.ultimaSubcategoriaCargada = [...subCategoria.categorias];
          this.myFormSub = this.busquedaForm.valueChanges
            .pipe(takeWhile(() => this.alive), distinctUntilChanged())
            .subscribe(() => this.cargarEntradas());
  
          this.subscriptions.push(this.myFormSub);
  
          // Cargar las entradas al iniciar
          this.cargarEntradas();
        } else {
          this.entradas = [];
        }
      });
  }
  
  cargarEntradas() {
    if (!this.categoriaActiva) return;
  
    this.restauracionService
      .getEntradasMicroservicesV2(
        Number(this.categoriaActiva.pkId),
        this.ventaService.clienteSeleccionadoValue,
        '3',
        this.filtrosTextForm.value.busqueda || ''
      )
      .subscribe((entradas: Entrada[]) => {
        if (
          this.ventaService.carritoValue.entradas.length > 0 &&
          this.ventaService.carritoValue.horarios.length > 0
        ) {
          this.refrescarCarrito();
        }
  
        this.entradas = entradas;
        if (!this.entradas || this.entradas.length === 0) return;
  
        let entradasIdsArr = [];
  
        this.entradas.forEach((entrada) => {
          if (entrada.TipoProducto === '2') {
            entrada.PromocionEntradas.forEach((item) => {
              entradasIdsArr.push(item.TipoEntradaId);
            });
          } else if (entrada.TipoProducto === '1') {
            entradasIdsArr.push(entrada.TipoEntradaId);
          }
        });
  
        const entradasIds = [...new Set(entradasIdsArr)].join();
        if (!entradasIds) return;
  
        this.entradaService.getRecintosEntrada(entradasIds).subscribe((r: any) => {
          if (!r.DatosResult) return;
  
          const recintos = r.DatosResult.RecintosDisponibles;
          this.entradas.forEach((e) => {
            const recintosArr = [];
            if (e.TipoProducto === '1') {
              recintosArr.push(...recintos.filter(rec => rec.TipoEntrada === e.TipoEntradaId));
            } else if (e.TipoProducto === '2') {
              e.PromocionEntradas.forEach((item) => {
                recintosArr.push(...recintos.filter(rec => rec.TipoEntrada === item.TipoEntradaId));
              });
            }
            e.recintos = recintosArr;
          });
        });
  
        this.setPage(1);
      });
  }

  seleccionCategoria(id: string, borrar: boolean = true): void {
    this.categoriaActiva = this.categorias.find((cat) => cat.pkId === id);
    //
    //
    // Limpiamos los filtros
    this.filtroTiposProducto = '';
    this.filtroGruposAsociados = '';
    this.filtroTiposNivel = '';
    this.filtradoPorGrupoAso = '';
    this.filtradoPorProducto = '';
    this.filtradoPorNivel = '';
    if (borrar) this.filtradoPorNombre = '';
    this.filtrosForm.controls['tipoproducto'].setValue('');
    this.filtrosForm.controls['rangoedad'].setValue('');
    this.filtrosForm.controls['tiponivel'].setValue('');
    if (borrar) this.filtrosTextForm.controls['busqueda'].setValue('');
    if (this.categoriaActiva && this.categoriaActiva.Descripcion == 'EXTERNO') {
      this.verSelectores = true;
    } else {
      this.verSelectores = false;
    }
    //
    let codigoCliente = this.ventaService.clienteSeleccionadoValue;
    if (
      this.configurationService.internetConfig.DatosResult.ClienteId ===
      this.ventaService.clienteSeleccionadoValue.Codigo
    ) {
      codigoCliente = '0';
    }
    this.restauracionService
      .getEntradasMicroservicesV2(
        Number(this.categoriaActiva.pkId),
        codigoCliente,
        '3',
        this.filtrosTextForm.value.busqueda || ''
      )
      .pipe(takeWhile(() => this.alive))
      .subscribe((entradas: Entrada[]) => {
        this.entradas = entradas;
        let entradasIdsArr = [];
        if (this.entradas && this.entradas.length > 0) {
          this.entradas.forEach((entrada) => {
            if (entrada.TipoProducto === '2') {
              entrada.PromocionEntradas.forEach((item) => {
                entradasIdsArr.push(item.TipoEntradaId);
              });
            } else if (entrada.TipoProducto === '1') {
              entradasIdsArr.push(entrada.TipoEntradaId);
            }
          });
          const set = new Set(entradasIdsArr);
          entradasIdsArr = [...set];
          const entradasIds = entradasIdsArr.join();
          this.entradaService
            .getRecintosEntrada(entradasIds)
            .subscribe((res: any) => {
              if (res.DatosResult === null) {
                return;
              }
              const recintos = res.DatosResult.RecintosDisponibles;
              this.entradas.forEach((e) => {
                const recintosArr = [];
                if (e.TipoProducto === '1') {
                  recintos.filter((recinto) => {
                    if (recinto.TipoEntrada === e.TipoEntradaId) {
                      recintosArr.push(recinto);
                    }
                  });
                } else if (e.TipoProducto === '2') {
                  e.PromocionEntradas.forEach((item) => {
                    e.recintos = recintos.filter((recinto) => {
                      if (recinto.TipoEntrada === item.TipoEntradaId) {
                        recintosArr.push(recinto);
                      }
                    });
                  });
                }
                e.recintos = [...recintosArr];
              });
            });
          //
          if (this.entradas && this.entradas.length > 0 && !borrar) {
            if (
              this.filtrosTextForm.value.busqueda &&
              this.filtrosTextForm.value.busqueda.length > 2
            ) {
              this.filtradoPorNombre = this.filtrosTextForm.value.busqueda;
              //
              this.entradas = this.entradas.filter(
                (item) =>
                  (item.NombreTipo &&
                    item.NombreTipo.toLowerCase().includes(
                      this.filtrosTextForm.value.busqueda.toLowerCase()
                    )) ||
                  (item.NombrePantalla &&
                    item.NombrePantalla.toLowerCase().includes(
                      this.filtrosTextForm.value.busqueda.toLowerCase()
                    )) ||
                  (item.NombreDisplay &&
                    item.NombreDisplay.toLowerCase().includes(
                      this.filtrosTextForm.value.busqueda.toLowerCase()
                    ))
              );
            }
          }
          //
          this.setPage(1);
        }
      });
  }

  eliminarEntrada(entrada: any): void {
    if (
      entrada.noEditable ||
      this.ventaService.carritoValue.reservaAgrupada == '1'
    ) {
      return;
    }
    const cantidadEntradas = this.ventaService.getCantidadPorEntrada(entrada);
    if (cantidadEntradas > 0) {
      this.entradaService.setEntradaSeleccionada(entrada);
      if (entrada.CaracteristicasEntrada) {
        entrada.CaracteristicasEntrada.splice(
          entrada.CaracteristicasEntrada.length - 1
        );
      }
      //<< Yaribel 20210504  Se ha modificado el codigo para indicar GrupoMotivoId tanto en entradas como en promociones y solo tiene valor si tiene motivos seleccionables
      if (
        entrada.GrupoMotivoId &&
        Number.parseInt(entrada.GrupoMotivoId, 10) > 0
      ) {
        this.modalService.open('selector-motivos');
        return;
      } else {
        this.eliminarEntradasVerificandoAforo(entrada);
      }
    }
  }

  eliminarEntradasVerificandoAforo(entrada: any) {
    if (this.ventaService.carritoValue.horarios.length > 0) {
      const recintosEliminar = [];
      const indicesEliminar = [];

      entrada.recintos.forEach((r) => recintosEliminar.push(r.Recinto));
      //
      this.ventaService.carritoValue.horarios.forEach((item, index) => {
        //
        if (recintosEliminar.includes(item.recinto.Recinto)) {
          if (item.cantidadxentrada && item.cantidadxentrada.length > 0) {
            if (
              !entrada.Caracteristicas ||
              entrada.Caracteristicas.NumPeriodos == ''
            ) {
              if (item.cantidad - 1 > 0) {
                item.cantidad = item.cantidad - 1;
                item.resumen = `${item.recinto.NombreRecinto} - ${moment(
                  this.fecha,
                  'YYYY/MM/DD'
                )
                  .locale(this.idioma)
                  .format('L')} ${item.sesion.HoraInicio} (${item.cantidad})`;
                // this.ventaService
                // .reservaAforo(item.sesion.RecintosSesionId, item.cantidad, entrada.TipoEntradaId)
                // .subscribe();
                this.ventaService
                  .reservaAforoProductos(
                    this.mapeaListaentradas2ListaProductos(
                      item.cantidadxentrada
                    ),
                    item.recinto.Recinto,
                    this.fechaYYYYMMDD2slash(
                      item.sesion.Fecha,
                      item.sesion.HoraInicio
                    )
                  )
                  .subscribe();
              } else {
                this.ventaService
                  .eliminarAforoSesion(item.sesion.RecintosSesionId)
                  .subscribe((data) => {});
                indicesEliminar.push(index);
              }
            } else {
              if (item.cantidadxentrada.length > 0) {
                // for (const entradaR of item.cantidadxentrada) {
                //   if (entradaR.entrada.TipoEntradaId ==  entrada.TipoEntradaId) {
                //     this.ventaService
                //     .reservaAforo(item.sesion.RecintosSesionId, entradaR.cantidad - 1, entrada.TipoEntradaId)
                //     .subscribe();
                //   }
                // }
                this.ventaService
                  .reservaAforoProductos(
                    this.mapeaListaentradas2ListaProductos(
                      item.cantidadxentrada,
                      entrada.TipoEntradaId
                    ),
                    item.recinto.Recinto,
                    this.fechaYYYYMMDD2slash(
                      item.sesion.Fecha,
                      item.sesion.HoraInicio
                    )
                  )
                  .subscribe();
              }
            }
          } else {
            if (item.cantidad - 1 > 0) {
              item.cantidad = item.cantidad - 1;
              item.resumen = `${item.recinto.NombreRecinto} - ${moment(
                this.fecha,
                'YYYY/MM/DD'
              )
                .locale(this.idioma)
                .format('L')} ${item.sesion.HoraInicio} (${item.cantidad})`;
              // <<Yaribel 20210112 cambiamos peticion para que llame a reservaAforoProductos

              // //JS 20200811 Este no se cambia porque no tiene productos
              // this.ventaService
              // .reservaAforo(item.sesion.RecintosSesionId, item.cantidad)
              // .subscribe();
              this.ventaService
                .reservaAforoProductos(
                  this.mapeaListaentradas2ListaProductos(
                    item.getTotalCantidadXEntradasSinPeriodos
                  ),
                  item.recinto.Recinto,
                  this.fechaYYYYMMDD2slash(
                    item.sesion.Fecha,
                    item.sesion.HoraInicio
                  )
                )
                .subscribe();

              // Yaribel 20210112 >>
            } else {
              this.ventaService
                .eliminarAforoSesion(item.sesion.RecintosSesionId)
                .subscribe((data) => {});
              indicesEliminar.push(index);
            }
          }
        }
      });
      if (indicesEliminar.length > 0) {
        indicesEliminar.forEach((indice) => {
          //
          if (indice > 0) {
            indice--;
          }
          this.ventaService.carritoValue.horarios.splice(indice, 1);
        });
      }
    }
    this.ventaService.removeEntrada(entrada);
  }

  agregarEntrada(entrada: Entrada): void {
    if (
      entrada.noEditable ||
      this.ventaService.carritoValue.reservaAgrupada == '1' ||
      (this.verSelectores &&
        (this.filtroTiposProducto == '' ||
          this.filtroGruposAsociados == '' ||
          this.filtroTiposNivel == ''))
    ) {
      return;
    }
    if (this.ventaService.carritoValue.horarios.length > 0) {
      const recintosAnadir = [];
      const indicesEliminar = [];

      if (entrada.TipoProducto === '1') {
        entrada.recintos.forEach((r) => recintosAnadir.push(r.Recinto));
        this.ventaService.carritoValue.horarios.forEach((item, index) => {
          if (recintosAnadir.includes(item.sesion.RecintoId.toString())) {
            // item.cantidad = item.cantidad + 1;

            if (item.sesion.RecintosSesionId) {
              this.ventaService
                .eliminarAforoSesion(item.sesion.RecintosSesionId)
                .subscribe((data) => {});
              indicesEliminar.push(index);
            } else {
              indicesEliminar.push(index);
            }
          }
        });
      } else if (entrada.TipoProducto === '2') {
        entrada.PromocionEntradas.forEach((item) => {
          entrada.recintos.forEach((r) => recintosAnadir.push(r.Recinto));
          this.ventaService.carritoValue.horarios.forEach((item2, index) => {
            if (
              recintosAnadir.includes(item2.sesion.RecintoId.toString()) &&
              item2.recinto.entradas.includes(item.TipoEntradaId)
            ) {
              // item.cantidad = item.cantidad + 1;
              if (item2.sesion.RecintosSesionId) {
                this.ventaService
                  .eliminarAforoSesion(item2.sesion.RecintosSesionId)
                  .subscribe((data) => {});
                indicesEliminar.push(index);
              } else {
                indicesEliminar.push(index);
              }
            }
          });
        });
      }
      if (indicesEliminar.length > 0) {
        indicesEliminar.forEach((indice) => {
          //
          if (indice > 0) {
            indice--;
          }
          this.ventaService.carritoValue.horarios.splice(indice, 1);
        });
      }
    }
    const objCaracteristicas = {
      Tipo: this.filtroTiposProducto,
      Rango: this.filtroGruposAsociados,
      Nivel: this.filtroTiposNivel,
      Profesor: '',
    };
    if (!entrada.CaracteristicasEntrada) {
      entrada.CaracteristicasEntrada = [];
    }
    if (this.filtroTiposProducto && this.filtroTiposProducto !== '') {
      //Controlamos que esto se pueda borrar
      entrada.CaracteristicasEntrada = this.getCaracteristicasxEntrada(entrada);
      entrada.CaracteristicasEntrada.push(objCaracteristicas);
    }
    this.entradaService.setEntradaSeleccionada(entrada);
    let motivoSeleccionable: any;
    //<< Yaribel 20210504  Se ha modificado el   codigo para indicar GrupoMotivoId tanto en entradas como en promociones y solo tiene valor si tiene motivos seleccionables
    if (
      this.ventaService.fechaSeleccionadaValue === '' &&
      this.ventaService.carritoValue.horarios.length > 0
    ) {
      entrada.Fecha = '';
    }
    if (
      entrada.GrupoMotivoId &&
      Number.parseInt(entrada.GrupoMotivoId, 10) > 0
    ) {
      this.modalService.open('selector-motivos');
      return;
    } else {
      // ;
      this.ventaService.sendEntrada(entrada);
    }

    this.visor.mostrarDisplayLineaAlbaran(
      this.getCantidadPorEntrada(entrada),
      entrada.PrecioEnDivisaReferencia
    );
  }

  agregarEntradas(entrada: Entrada, cantidad: number): void {
    this.entradaService.setEntradaSeleccionada(entrada);

    //<< Yaribel 20210504  Se ha modificado el codigo para indicar GrupoMotivoId tanto en entradas como en promociones y solo tiene valor si tiene motivos seleccionables
    if (
      entrada.GrupoMotivoId &&
      Number.parseInt(entrada.GrupoMotivoId, 10) > 0
    ) {
      this.modalService.open('selector-motivos');
      return;
    } else {
      const objCaracteristicas = {
        Tipo: this.filtroTiposProducto,
        Rango: this.filtroGruposAsociados,
        Nivel: this.filtroTiposNivel,
        Profesor: '',
      };
      if (this.filtroTiposProducto && this.filtroTiposProducto !== '') {
        entrada.CaracteristicasEntrada =
          this.getCaracteristicasxEntrada(entrada);
        entrada.CaracteristicasEntrada.push(objCaracteristicas);
      }
      this.ventaService.sendEntradas(entrada, cantidad);
    }

    this.visor.mostrarDisplayLineaAlbaran(
      cantidad,
      entrada.PrecioEnDivisaReferencia
    );
  }

  getCantidadPorEntrada(entrada: Entrada): number {
    if (this.ventaService.carritoValue) {
      return this.ventaService.getCantidadPorEntrada(entrada);
    }
    return 0;
  }

  getCaracteristicasxEntrada(entrada: Entrada): any[] {
    if (this.ventaService.carritoValue) {
      return this.ventaService.getCaracteristicasxEntrada(entrada);
    }
    return [];
  }

  focusValue(event: any, entrada: any): void {
    if (entrada.noEditable) {
      return;
    }
    this.entradaService.setEntradaSeleccionada(entrada);
    //<< Yaribel 20210504  Se ha modificado el codigo para indicar GrupoMotivoId tanto en entradas como en promociones y solo tiene valor si tiene motivos seleccionables
    if (
      entrada.GrupoMotivoId &&
      Number.parseInt(entrada.GrupoMotivoId, 10) > 0
    ) {
      this.modalService.open('selector-motivos');
      return;
    }

    event.srcElement.value = '';
    this.numpadService.selected = event.srcElement;
    this.numpadService.entrada = entrada;
    this.formControlService.setCurrentFormGroup(
      null,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
    if (event.srcElement.getAttribute('category') === 'CANTIDAD') {
      this.numpadService.commaDisabled = true;
    } else {
      this.numpadService.commaDisabled = false;
    }
  }

  unfocusValue(event: any): void {
    if (event.srcElement.value === '') {
      this.numpadService.setNumpadOrder('reset');
    }
  }

  seleccionarEntrada(entrada: Entrada): void {
    const input = document.getElementById(entrada.idTarifa);
    this.numpadService.selected = input;
    this.numpadService.entrada = entrada;
    this.formControlService.setCurrentFormGroup(
      null,
      input,
      input.getAttribute('category')
    );
    // tslint:disable-next-line: deprecation
    if ((<HTMLElement>event.target).getAttribute('category') === 'cantidad') {
      this.numpadService.commaDisabled = true;
    } else {
      this.numpadService.commaDisabled = false;
    }
    this.entradaSeleccionada = entrada;
  }

  cambiodeFiltro(filtro: any, clase: string): void {
    switch (clase) {
      case 'TP':
        this.filtroTiposProducto = filtro.value;
        this.filtradoPorProducto = filtro.value;
        this.enviarFiltros();
        break;
      case 'RE':
        this.filtroGruposAsociados = filtro.value;
        this.filtradoPorGrupoAso = filtro.value;
        this.enviarFiltros();
        break;
      case 'TN':
        this.filtroTiposNivel = filtro.value;
        this.filtradoPorNivel = filtro.value;
        this.enviarFiltros();
        break;
      default:
    }
  }

  eliminarFiltro(filtro: any, clase: string): void {
    switch (clase) {
      case 'TP':
        this.filtroTiposProducto = '';
        this.filtradoPorProducto = '';
        this.filtrosForm.get('tipoproducto').setValue('');
        this.enviarFiltros();
        break;
      case 'RE':
        this.filtroGruposAsociados = '';
        this.filtradoPorGrupoAso = '';
        this.filtrosForm.get('rangoedad').setValue('');
        this.enviarFiltros();
        break;
      case 'TN':
        this.filtroTiposNivel = '';
        this.filtradoPorNivel = '';
        this.filtrosForm.get('tiponivel').setValue('');
        this.enviarFiltros();
        break;
      case 'N':
        this.filtradoPorNombre = '';
        this.filtrosTextForm.get('busqueda').setValue('');
        this.enviarFiltros();
        break;
      default:
    }
  }

  cambiodeFiltroTexto() {
    this.enviarFiltros();
  }

  enviarFiltros(): void {
    this.restauracionService
      .getEntradasMicroservicesV2(
        Number(this.categoriaActiva.pkId),
        this.ventaService.clienteSeleccionadoValue,
        '3',
        this.filtrosTextForm.value.busqueda || ''
      )
      .pipe(takeWhile(() => this.alive))
      .subscribe((entradas: Entrada[]) => {
        this.entradas = entradas;
        let entradasIdsArr = [];
        if (this.entradas != null && this.entradas.length > 0) {
          this.entradas.forEach((entrada) => {
            if (entrada.TipoProducto === '2') {
              entrada.PromocionEntradas.forEach((item) => {
                entradasIdsArr.push(item.TipoEntradaId);
              });
            } else if (entrada.TipoProducto === '1') {
              entradasIdsArr.push(entrada.TipoEntradaId);
            }
          });
        }
        const set = new Set(entradasIdsArr);
        entradasIdsArr = [...set];
        const entradasIds = entradasIdsArr.join();
        this.entradaService
          .getRecintosEntrada(entradasIds)
          .subscribe((res: any) => {
            if (res.DatosResult === null) {
              return;
            }
            const recintos = res.DatosResult.RecintosDisponibles;
            if (this.entradas != null && this.entradas.length > 0) {
              this.entradas.forEach((e) => {
                const recintosArr = [];
                if (e.TipoProducto === '1') {
                  recintos.filter((recinto) => {
                    if (recinto.TipoEntrada === e.TipoEntradaId) {
                      recintosArr.push(recinto);
                    }
                  });
                } else if (e.TipoProducto === '2') {
                  e.PromocionEntradas.forEach((item) => {
                    e.recintos = recintos.filter((recinto) => {
                      if (recinto.TipoEntrada === item.TipoEntradaId) {
                        recintosArr.push(recinto);
                      }
                    });
                  });
                }
                e.recintos = [...recintosArr];
              });
            }
          });
        if (this.entradas && this.entradas.length > 0) {
          if (
            this.filtrosTextForm.value.busqueda &&
            this.filtrosTextForm.value.busqueda.length > 2
          ) {
            this.filtradoPorNombre = this.filtrosTextForm.value.busqueda;
            //
            this.entradas = this.entradas.filter(
              (item) =>
                (item.NombreTipo &&
                  item.NombreTipo.toLowerCase().includes(
                    this.filtrosTextForm.value.busqueda.toLowerCase()
                  )) ||
                (item.NombrePantalla &&
                  item.NombrePantalla.toLowerCase().includes(
                    this.filtrosTextForm.value.busqueda.toLowerCase()
                  )) ||
                (item.NombreDisplay &&
                  item.NombreDisplay.toLowerCase().includes(
                    this.filtrosTextForm.value.busqueda.toLowerCase()
                  )) ||
                (item.BarCode &&
                  item.BarCode.toLowerCase().includes(
                    this.filtrosTextForm.value.busqueda.toLowerCase()
                  ))
            );
          }
          this.setPage(1);
        }
      });
  }

  setPage(page: number): void {
    this.pager = this.pagerService.getPager(this.entradas.length, page, 9);
    this.pagedItems = this.entradas.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );

    this.pager.pages = this.pager.pages.filter(
      (num) => num <= this.pager.totalPages
    );

    while (this.pager.pages.length < 6 && this.pager.pages[0] > 1) {
      this.pager.pages.unshift(this.pager.pages[0] - 1);
    }
    this.ventaService.carritoValue.fecha =
      this.getFechaSeleccionada() ||
      moment().locale(this.idioma).format('YYYY/MM/DD HH:mm:ss');
  }

  getFechaSeleccionada(): string {
    /* if (this.ventaService.carritoValue.entradas.length > 0) {
          this.refrescarCarrito();
        } */
    if (this.ventaService.fechaSeleccionadaValue) {
      return moment(this.ventaService.fechaSeleccionadaValue)
        .locale(this.idioma)
        .format('YYYY/MM/DD HH:mm:ss');
    }
  }

  deberiaDestacarse(id: string) {
    const categorias = this.ventaService.carritoValue.entradas.map((linea) => {
      return linea.entrada.CategoriaId;
    });
    return this.uniq_fast(categorias).includes(id);
  }

  inputDeTeclado(event: any, entrada: Entrada) {
    event.preventDefault();
    const charCode = event.keyCode;
    this.numpadService.selected = event.srcElement;
    this.numpadService.entrada = entrada;
    this.formControlService.setCurrentFormGroup(
      null,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );

    if (this.isDeleteButtonPressed(charCode)) {
      this.formControlService.deleteChar();
      return;
    }

    if (this.isEnterButton(charCode)) {
      this.formControlService.unfocus();
      event.srcElement.blur();
      return;
    }

    if (this.isNumberKey(charCode)) {
      this.formControlService.inputChar(event.key);
      return;
    }
  }

  isNumberKey(charCode: number) {
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  isDeleteButtonPressed(charCode: number) {
    return charCode === 8 ? true : false;
  }

  isEnterButton(charCode: number) {
    return charCode === 13 ? true : false;
  }

  private uniq_fast(a) {
    const seen = {};
    const out = [];
    const len = a.length;
    let j = 0;
    for (let i = 0; i < len; i++) {
      const item = a[i];
      if (seen[item] !== 1) {
        seen[item] = 1;
        out[j++] = item;
      }
    }
    return out;
  }

  openModal(id: string) {
    this.ventaService.verCalendario.next(true);
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  nextDia() {
    /* if (this.ventaService.carritoValue.entradas.length > 0) {
          this.refrescarCarrito();
        } */
    const futuribleDate = moment(this.ventaService.fechaSeleccionadaValue).add(
      1,
      'days'
    );
    //
    if (
      this.ventaService.carritoValue.horariosPrevios &&
      this.ventaService.carritoValue.horariosPrevios.length > 0
    ) {
      this.ventaService.carritoValue.resetearCarritoDeLocalizador();
    }
    if (
      this.ventaService.disabledDaysValue.includes(
        futuribleDate.format('DD/MM/YYYY')
      )
    ) {
      this.ventaService.setFechaSeleccionadaValue(
        this.ventaService.fechaSeleccionadaValue.add(2, 'days')
      );
      return;
    }
    if (this.ventaService.fechaSeleccionadaValue) {
      this.ventaService.setFechaSeleccionadaValue(
        this.ventaService.fechaSeleccionadaValue.add(1, 'days')
      );
      return;
    }
  }

  backDia() {
    /*  if (this.ventaService.carritoValue.entradas.length > 0) {
          this.refrescarCarrito();
        } */
    const futuribleDate = moment(
      this.ventaService.fechaSeleccionadaValue
    ).subtract(1, 'days');
    if (
      this.ventaService.fechaSeleccionadaValue.isSameOrBefore(moment(), 'day')
    ) {
      return;
    }
    if (
      this.ventaService.carritoValue.horariosPrevios &&
      this.ventaService.carritoValue.horariosPrevios.length > 0
    ) {
      this.ventaService.carritoValue.resetearCarritoDeLocalizador();
    }
    if (
      this.ventaService.disabledDaysValue.includes(
        futuribleDate.format('DD/MM/YYYY')
      )
    ) {
      this.ventaService.setFechaSeleccionadaValue(
        this.ventaService.fechaSeleccionadaValue.subtract(2, 'days')
      );
      return;
    }
    if (this.ventaService.fechaSeleccionadaValue) {
      this.ventaService.setFechaSeleccionadaValue(
        this.ventaService.fechaSeleccionadaValue.subtract(1, 'days')
      );
      return;
    }
  }

  setValueWhithoutDate() {
    this.ventaService.fechaSeleccionada = null;
    return;
  }

  getNombreCliente() {
    if (this.ventaService.clienteSeleccionadoValue) {
      return this.ventaService.clienteSeleccionadoValue.NombreCliente;
    }
    return '';
  }

  tienePermiso(accion: string) {
    const conf = {
      toast: false,
      elevarUsuario: false,
    };
    return this.permisosService.puedeAccionar(
      this.user.currentUserValue,
      accion,
      conf
    );
  }

  refrescarCarrito() {
    let items = '';
    const entradasCarrito = [];
    let fecha =
      this.ventaService.fechaSeleccionadaValue !== ''
        ? this.ventaService.fechaSeleccionadaValue.format('YYYY/MM/DD HH:mm:ss')
        : moment().format('YYYY/MM/DD HH:mm:ss');
    this.ventaService.carritoValue.fecha = moment(
      this.ventaService.carritoValue.fecha
    ).format('YYYY/MM/DD HH:mm:ss');

    this.ventaService.carritoValue.entradas.forEach((data) => {
      entradasCarrito.push(data.entrada);
      data.entrada.Fecha = moment(fecha).format('YYYY/MM/DD');
      if (data.entrada.TipoProducto === '2') {
        items =
          items +
          `[${data.entrada.TipoProducto},${data.entrada.TipoPromocionId}]`;
      } else if (data.entrada.TipoProducto === '1') {
        items =
          items +
          `[${data.entrada.TipoProducto},${data.entrada.TipoEntradaId}]`;
      }
    });
    items = items.replace(/]\[/g, '],[');

    this.recalculaCarritoSub = this.entradaService
      .recalcularCarrito(items, this.ventaService.carritoValue.fecha)
      .subscribe((data) => {
        this.ventaService.clearCarrito();
        this.ventaService.carritoValue.entradas.forEach((item) => {
          data.forEach((d) => {
            d.Tipo = d.Tipo ? this.filtroTiposProducto : null;
            d.Rango = d.Rango ? this.filtroGruposAsociados : null;
            d.Nivel = d.Nivel ? this.filtroTiposNivel : null;
            if (item.TipoProducto === '2') {
              if (item.TipoPromocionId === d.TipoPromocionId) {
                if (item.recintos !== undefined) {
                  d.recintos = item.recintos;
                }
                this.ventaService.sendEntrada(d);
              }
            } else if (item.TipoProducto === '1') {
              if (item.TipoEntradaId === d.TipoEntradaId) {
                if (item.recintos !== undefined) {
                  d.recintos = item.recintos;
                }
                this.ventaService.sendEntrada(d);
              }
            }
          });
        });
      });

    this.subscriptions.push(this.recalculaCarritoSub);
  }

  filroCategoriaSiguiente() {
    this.bucleCategorias(1);
  }

  filroCategoriaPrevio() {
    this.bucleCategorias(-1);
  }

  async bucleCategorias(paso: number) {
    //let id:number = +(this.categoriaActiva && this.categoriaActiva.Id ? this.categoriaActiva.Id : '1');
    let indiceCategoria: number = this.categorias.indexOf(this.categoriaActiva);
    let salidaLoop: Boolean = false;
    let i = this.categorias.length;
    let respuesta = [];
    do {
      //id = id + paso;
      indiceCategoria = indiceCategoria + paso;
      //if (id < 1) { id = this.categorias.length; } else if (id >= this.categorias.length) ( id = 1);
      if (indiceCategoria < 0) {
        indiceCategoria = this.categorias.length;
      } else if (indiceCategoria >= this.categorias.length) indiceCategoria = 0;
      await this.restauracionService
        .getEntradasMicroservicesV2(
          Number(this.categoriaActiva.pkId),
          this.ventaService.clienteSeleccionadoValue,
          '3',
          this.filtrosTextForm.value.busqueda || ''
        )
        .toPromise()
        .then((datos) => {
          if (datos && Object.keys(datos).length > 0) {
            //
            salidaLoop = true;
            //entradas = datos;
            //this.seleccionCategoria(id.toString(), false);
            this.seleccionCategoria(
              this.categorias[indiceCategoria].pkId,
              false
            );
            // si las categorias no son correlativas??? comprobar por index en lugar de por id
          }
        });
      //
      //;
      //if (entradas && entradas.length > 0){}
      i--;
    } while (!salidaLoop && i > 0);
  }

  mapeaListaentradas2ListaProductos(listaentradas, filtroProducto?): string[] {
    let respuesta: string[] = [];
    listaentradas.forEach((element) => {
      let idProducto = '';
      if (element.entrada.TipoProducto == '2') {
        if (
          !filtroProducto ||
          filtroProducto == element.entrada.TipoPromocionId
        )
          idProducto =
            '2,' +
            element.entrada.TipoPromocionId +
            ',' +
            element.cantidad.toString();
      } else if (element.entrada.TipoProducto == '3') {
        if (!filtroProducto || filtroProducto == element.entrada.TipoAbonadoId)
          idProducto =
            '3,' +
            element.entrada.TipoAbonadoId +
            ',' +
            element.cantidad.toString();
      } else {
        if (!filtroProducto || filtroProducto == element.entrada.TipoEntradaId)
          idProducto =
            '1,' +
            element.entrada.TipoEntradaId +
            ',' +
            element.cantidad.toString();
      }
      respuesta.push(idProducto);
    });
    //if (respuesta.length > 0) respuesta = respuesta.slice(0, -1);
    return respuesta;
  }

  fechaYYYYMMDD2slash(fecha, hora) {
    let respuesta = '';
    if (fecha && fecha.length == 8) {
      respuesta =
        fecha.substring(0, 4) +
        '/' +
        fecha.substring(4, 6) +
        '/' +
        fecha.substring(6);
    }
    if (hora && hora.length == 5) {
      respuesta = respuesta + ' ' + hora + ':00';
    } else if (hora && hora.length == 8) {
      respuesta = respuesta + ' ' + hora;
    } else {
      respuesta = respuesta + ' ' + '23:59:00';
    }
    return respuesta;
  }

  inicializarCategorias(categorias: any[]): any[] {
    return categorias.map((cat) => ({
      ...cat,
      expandido: false,
      categorias: cat.categorias
        ? this.inicializarCategorias(cat.categorias)
        : [],
    }));
  }

  /**
   * Muestra las subcategorías de la categoría seleccionada.
   */
  mostrarSubcategorias(categoria: any, seleccionarUltimaSubcategoria: boolean = false) {
    if (this.categoriaActiva && this.categoriaActiva !== categoria) {
      this.categoriaActiva.expandido = false;
    }
  
    // Actualiza la categoría activa
    this.categoriaActiva = categoria;
  
    // Expande o contrae la categoría
    categoria.expandido = !categoria.expandido;
  
    // Manejo de subcategorías
    if (categoria.expandido) {
      if (categoria.categorias && categoria.categorias.length > 0) {
        this.subcategoriasSeleccionadas = categoria.categorias;
        this.ultimaSubcategoriaCargada = [...categoria.categorias]; // Guarda la última categoría cargada
  
        // Si se debe seleccionar la última subcategoría disponible
        if (seleccionarUltimaSubcategoria) {
          this.categoriaActiva = categoria.categorias[categoria.categorias.length - 1];
        }
      } else {
        this.subcategoriasSeleccionadas = this.ultimaSubcategoriaCargada || []; // Si no hay nuevas, usa la última
      }
    } else {
      this.subcategoriasSeleccionadas = this.ultimaSubcategoriaCargada || []; // Mantén la última cargada
    }
  
    // Llamada al servicio para obtener las entradas con la nueva categoría activa
    this.restauracionService
      .getEntradasMicroservicesV2(
        Number(this.categoriaActiva.pkId),
        this.ventaService.clienteSeleccionadoValue,
        '3',
        this.filtrosTextForm.value.busqueda || ''
      )
      .subscribe((entradas: Entrada[]) => {
        if (
          this.ventaService.carritoValue.entradas.length > 0 &&
          this.ventaService.carritoValue.horarios.length > 0
        ) {
          this.refrescarCarrito();
        }
        this.entradas = entradas;
  
        let entradasIdsArr = [];
        if (this.entradas && this.entradas.length > 0) {
          this.entradas.forEach((entrada) => {
            if (entrada.TipoProducto === '2') {
              entrada.PromocionEntradas.forEach((item) => {
                entradasIdsArr.push(item.TipoEntradaId);
              });
            } else if (entrada.TipoProducto === '1') {
              entradasIdsArr.push(entrada.TipoEntradaId);
            }
          });
  
          const set = new Set(entradasIdsArr);
          entradasIdsArr = [...set];
          const entradasIds = entradasIdsArr.join();
  
          this.entradaService
            .getRecintosEntrada(entradasIds)
            .subscribe((r: any) => {
              if (r.DatosResult === null) {
                return;
              }
              const recintos = r.DatosResult.RecintosDisponibles;
              this.entradas.forEach((e) => {
                const recintosArr = [];
                if (e.TipoProducto === '1') {
                  recintos.filter((recinto) => {
                    if (recinto.TipoEntrada === e.TipoEntradaId) {
                      recintosArr.push(recinto);
                    }
                  });
                } else if (e.TipoProducto === '2') {
                  e.PromocionEntradas.forEach((item) => {
                    e.recintos = recintos.filter((recinto) => {
                      if (recinto.TipoEntrada === item.TipoEntradaId) {
                        recintosArr.push(recinto);
                      }
                    });
                  });
                }
                e.recintos = [...recintosArr];
              });
            });
  
          this.setPage(1);
        }
      });
  }
}
