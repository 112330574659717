import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfiguration } from "src/app/app.configuration";
import { MicroserviceTokenResponse } from "../interfaces/microservices";

const httpOptions = {
	headers: new HttpHeaders({
		"Content-Type": "application/json",
		"Cache-Control": "no-cache",
	}),
};


@Injectable({
	providedIn: "root",
})
export class MicroserviceService {
  
	private urlApi = "";
  
	constructor(private config: AppConfiguration, private http: HttpClient) {
		this.urlApi = this.config.getConfig("API_URL_MS");
	}

	getTokenMicroservice(
		username: string,
		password: string
	): Observable<MicroserviceTokenResponse> {
		const body = {
			usuario: this.config.getConfig('API_TOKEN_USER'),
			contrasena: this.config.getConfig('API_TOKEN_PASSWORD'),
		};

		const URL = `${this.urlApi}/Token`;

		const response = this.http.post<MicroserviceTokenResponse>(URL, body, {
			headers: {
				"Content-Type": "application/json",
				"Cache-Control": "no-cache",
			}
		});

		return response;
	}


	refreshToken(data: MicroserviceTokenResponse) {
		const body = {
			token: data.token,
			refreshToken: data.refreshToken,
		};

		const URL = `${this.urlApi}/Refresh`;

		const response = this.http.post<MicroserviceTokenResponse>(URL, body, httpOptions);

		return response;
	}


	loginMicroservice(data: MicroserviceTokenResponse) {
		localStorage.setItem("tokenMicroservice", data.token);
		localStorage.setItem("microserviceData", JSON.stringify(data));
  }
}
