import { NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import {
  SelectorPromocionesComponent,
  VentaComponent,
  StepperComponent,
  BusquedaComponent,
  SelectorEntradasComponent,
  SelectorHorariosComponent,
  SelectorMotivosComponent,
  SelectorProcedenciaComponent,
  SelectorPagoComponent,
  AccordionComponent,
  PanelComponent,
  CellComponent
} from './index';

import { SharedModule } from 'src/app/shared/shared.module';
import { MyCurrencyPipe } from 'src/app/shared/pipes/myCurrency.pipe';
import { SelectorEntradasBotoneraComponent } from './components/selector-entradas-botonera/selector-entradas-botonera.component';
import { ModalSelectorMotivosComponent } from './components/modal-selector-motivos/modal-selector-motivos.component';
import { SelectorMasEntradasComponent } from './components/selector-mas-entradas/selector-mas-entradas.component';
import { ResumenCompraComponent } from './components/resumen-compra/resumen-compra.component';
import { DialogMail } from './dialog-mail/dialog-mail.component';
//import { TranslateService } from 'src/app/shared/services/translate.service'; // Yaribel 20210105 Comentamos para unificar el translate service en un solo modulo
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

//<< Yaribel 20210105 Comentamos para unificar el translate service en un solo modulo
// export function setupTranslateFactory(service: TranslateService): Function {
//   return () => service.use(navigator.language);
// }
// Yaribel 20210105 >>

@NgModule({
  declarations: [
    VentaComponent,
    StepperComponent,
    BusquedaComponent,
    SelectorEntradasComponent,
    SelectorHorariosComponent,
    ResumenCompraComponent,
    SelectorMotivosComponent,
    SelectorProcedenciaComponent,
    SelectorPromocionesComponent,
    SelectorPagoComponent,
    AccordionComponent,
    PanelComponent,
    CellComponent,
    DialogMail,
    SelectorEntradasBotoneraComponent,
    SelectorMasEntradasComponent,
    ModalSelectorMotivosComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    SharedModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatDialogModule
  ],
  exports: [ResumenCompraComponent, DialogMail],
  entryComponents: [DialogMail],
  providers: [

    //<< Yaribel 20210105 Comentamos para unificar el translate service en un solo modulo
    // TranslateService,
    // MyCurrencyPipe,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: setupTranslateFactory,
    //   deps: [TranslateService],
    //   multi: true
    // },
        // Yaribel 20210105 >>
          {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class VentaModule {}
