import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RestauracionComponent } from './restauracion.component';
import { RouterModule } from '@angular/router';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from 'src/app/shared/shared.module';
import { RestauracionSelectorMasEntradasComponent } from './components/selector-mas-entradas/selector-mas-entradas.component';
import { VentaModule } from '../venta/venta.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
@NgModule({
  declarations: [
    RestauracionComponent, 
    RestauracionSelectorMasEntradasComponent,
  ],
  imports: [
    CommonModule,
    PerfectScrollbarModule, 
    RouterModule, 
    FormsModule, 
    ReactiveFormsModule,
    PerfectScrollbarModule,
    SharedModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    VentaModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
})
export class RestauracionModule {}
