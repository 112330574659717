import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Albaran } from '../../../models/albaran.model';
import { FacturaService } from 'src/app/shared/services/factura.service';
import { PrintService } from 'src/app/shared/services/print.service';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { ConfigurationService } from 'src/app/core/conf/configuration.service';
import { PagoService } from 'src/app/modules/venta/services/pago.service';
import { DialogMail } from 'src/app/modules/venta/dialog-mail/dialog-mail.component';
import { ErrorToast } from 'src/app/core/toast/error.toast';
import { SuccessToast } from 'src/app/core/toast/success.toast';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AppConfiguration } from 'src/app/app.configuration';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'ticketing-detalle-box',
  templateUrl: './detalle-box.component.html',
  styleUrls: ['./detalle-box.component.scss']
})
export class DetalleBoxComponent implements OnInit {
  @Input() albaran: Albaran;
  hayCodigoPromocional = false;
  codigoPromocional = '';
  divisaSimboloDecimal: string; 
  divisaSimbSeparacionMiles: string;  
  divisaSimbolo: string;
  divisaDecimales: number;
  divisaPosicion: string;
  divisaSimboloDerecha: string;
  albaranPrint;
  showEnvioMail: boolean;
  enviarMail: boolean;
  mails: string;
  stringMails: string;  

  constructor(
    private facturaService: FacturaService,
    private printService: PrintService,
    private translate: TranslateService,
    private configurationService: ConfigurationService,
    private pago: PagoService,
    public dialog: MatDialog,
    private toastService: ToastrService,
    private config: AppConfiguration,
    private http: HttpClient
  ) {
    //Verificar check "Enviar mail"
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));    
    const body = {
      ConexionIacpos: conexionId,
      CodigoApp: this.config.getConfig('CODIGO_APLICACION'),
      CodigoModulo: '',
      Id: this.configurationService.datosTPVPathValue.pkId 
    };

    this.http
      .post(
        `${this.config.getConfig('API_URL')}api/Configuracion/GetAppConfig`,
        body
      )
      .subscribe((res: any) => {          
        const configApp = (res && res.DatosResult) ? res.DatosResult : undefined;
        if(configApp) {
          this.configurationService.setDeviceConfiguration(res.DatosResult);      
          this.showEnvioMail = this.pago.showEnvioMail(configApp);
          this.enviarMail = this.pago.checkEnvioMail(configApp); 
        }
      });

  }

  ngOnInit() {
    let cadenaMails = '';
    if (this.albaran) {
      this.albaran.AlbaranItems.forEach(item => {
        if (item.CodigoPromocional.trim() !== '') {
          this.hayCodigoPromocional = true;
          this.codigoPromocional = item.CodigoPromocional;
          return true;
        }
      });

      if(this.albaran.EmailsEnvio && this.albaran.EmailsEnvio.length > 0) {
        let cadenaComas = '';      
        this.albaran.EmailsEnvio.forEach((c) => {
          cadenaComas += c.length > 0 ? c+',' : c;
        });
        cadenaMails = cadenaComas.slice(0, -1);
      }
    }
    
    this.divisaSimbSeparacionMiles = this.configurationService.divisasValue[0].SimbSeparacionMiles;
    this.divisaSimboloDecimal = this.configurationService.divisasValue[0].SimboloDecimal;
    this.divisaSimbolo = this.configurationService.divisasValue[0].simbolo;
    this.divisaDecimales = Number(this.configurationService.divisasValue[0].NumeroDecimales);
    this.divisaPosicion = this.configurationService.divisasValue[0].PosicionDivisa;
    this.divisaSimboloDerecha = this.configurationService.divisasValue[0].SimboloDerecha;

    /* this.showEnvioMail = this.pago.showEnvioMail();
    this.enviarMail = this.pago.checkEnvioMail();  */   
    this.pago.setStringMailGestionValue(cadenaMails); 
    this.pago.setSendMailGestionValue(false); 
  }

  openDialog(): void {
    if(this.pago.stringMailGestionValue) {
      this.stringMails = this.pago.cadenaMails(this.pago.stringMailGestionValue);
    }
    const dialogRef = this.dialog.open(DialogMail, {
      data: { mails: this.stringMails }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.mails = result;
      if (this.mails) {
        this.pago.setStringMailGestionValue(this.mails);
        this.stringMails = this.pago.cadenaMails(this.mails);
        this.facturaService.impresionFactura(this.albaranPrint, this.mails)
        .pipe(
          catchError((error) => {
            this.toastService.error('Error', this.translate.instant('ERROR_MAILS_FACTURA'), {
              toastComponent: ErrorToast,
              timeOut: 5000,
            });
            return of(null);
          })
        )
        .subscribe((data) => {
          if(!data || !data.emailEnviado || data.emailEnviado === 0){
            this.toastService.error('Error', this.translate.instant('ERROR_MAILS_FACTURA'), {
              toastComponent: ErrorToast,
              timeOut: 5000,
            });
          } else {
            if(data.emailEnviado && data.emailEnviado === 1) {
              this.toastService.success(this.translate.instant('SUCCESS_MAILS_FACTURA'), '', {
                toastComponent: SuccessToast,
                timeOut: 5000,
              });
            }   
          }    
        });      
      }      
    });
  }

  imprimir(albaran) { 
    this.albaranPrint = albaran;
    if (this.enviarMail) {      
      this.openDialog();            
    } else {
      this.facturaService.impresionFactura(albaran).subscribe();
    }         

    // this.facturaService.pdfFactura(albaran).subscribe(res => {
    //   if (res) {
    //     this.printService.printFacturaPDF(res.DatosResult);
    //   }
    // });
  }  

    private generarFOP(fop) {
    switch (fop) {
      // case 'CA':
      //   return 'EFECTIVO';
      // case 'CC':
      //   return 'TARJETA';
      // case 'TR':
      //   return 'TRANSFERENCIA';
      // case 'TM':
      //   return 'TARJETA MONEDERO';  // Yaribel 12112020  Añadimos la etiqueta para que se visualice el  nombre de la forma de pago TM en el detalle de la venta
      // case 'DP':
      //  return 'APLAZADO';  // Yaribel 20201209  Añadimos la etiqueta para que se visualice el  nombre de la forma de pago DP en el detalle de la venta
      // case 'PR':
      //   return 'PREPAGO'; //Yaribel 20201217 añadimos forma de pago Prepago 
      // default:
      //  return 'EFECTIVO';
       case 'CA':
        return this.translate.instant('EFECTIVO');
      case 'CC':
        return this.translate.instant('TARJETA');
      case 'TR':
        return this.translate.instant('TRANSFERENCIA');
      case 'TM':
        return this.translate.instant('JUSTIFICANTE_TARJETA_MONEDERO');
      case 'DP':
        return this.translate.instant('APLAZADO');
      case 'PR':
        return this.translate.instant('PREPAGO');  
      default:
        return this.translate.instant('EFECTIVO');
    }
  }

  formatearPrecio(precio) {
    if (typeof precio === 'string') {
      precio = Number.parseFloat(precio.replace(',', '.'));
    }
    return precio.toFixed(2);
  }

  getNumAlbaran(registro){
    let texto = '';
    if(registro.TpvAsociado && registro.AlbaranAsociado)
    texto = '(' + registro.TpvAsociado + '-' + registro.AlbaranAsociado + ')';
    return texto;
  }
}
