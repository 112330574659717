import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PrintService } from '../../services/print.service';
import { Subscription } from 'rxjs';
import { LocalizadorService } from 'src/app/modules/localizador/services/localizador.service';
import { TurnoService } from 'src/app/core';
import { AppConfiguration } from 'src/app/app.configuration';
import * as moment from 'moment';
import { OpcionesImpresionQR, PagoService } from '../../../modules/venta/services/pago.service';
import { AlbaranService } from 'src/app/modules/gestionar-venta/services/albaran.service';
import { flatMap } from 'rxjs/operators';
import { ConfigurationService } from '../../../core/conf/configuration.service';
import { ReservaItem } from '../../../modules/localizador/models/reserva.model';
import { MatDialog } from '@angular/material';
import { DialogMail } from 'src/app/modules/venta/dialog-mail/dialog-mail.component';

@Component({
  selector: 'ticketing-impresion-localizador-modal',
  templateUrl: './impresion-localizador-modal.component.html',
  styleUrls: ['./impresion-localizador-modal.component.scss']
})
export class ImpresionLocalizadorModalComponent implements OnInit {
  @Input() id: string;
  private element: any;
  disabledEntrar = false;
  formSubscription: Subscription;
  opcionesImpresionLocalizadorForm: FormGroup;
  ticketsProductosArr: any[];

  showEnvioMail: boolean;
  enviarMail: boolean;
  mails: string;
  stringMails: string;

  constructor(
    private modalService: ModalService,
    el: ElementRef,
    private router: Router,
    private fb: FormBuilder,
    private printService: PrintService,
    private localizadorService: LocalizadorService,
    private config: AppConfiguration,
    private turnoService: TurnoService,
    private pagoService: PagoService,
    private albaranService: AlbaranService,
    private configurationService: ConfigurationService,
    public dialog: MatDialog
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    this.opcionesImpresionLocalizadorForm = this.fb.group({
      imprimirTodo: false,
      entradas: false,
      etiquetas: false
    });

    const modal = this;
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }
    document.body.appendChild(this.element);
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
    
    //this.showEnvioMail = this.pagoService.showEnvioMail();
    //this.enviarMail = this.pagoService.checkEnvioMail();
    // this.showEnvioMail = true;
    this.sendMailModal();  
  }

  openDialog(): void {
    if(this.pagoService.stringMailGestionValue) {
      this.stringMails = this.pagoService.cadenaMails(this.pagoService.stringMailGestionValue);
    }
    const dialogRef = this.dialog.open(DialogMail, {
      data: { mails: this.stringMails }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.mails = result;
      if (this.mails) {
        this.pagoService.setStringMailGestionValue(this.mails);
        this.stringMails = this.pagoService.cadenaMails(this.mails);
        this.pagoService.setSendMailGestionValue(true);
        this.aceptarOpcionesEImprimir(this.mails);      
      }      
    });
  }

  public sendMailModal = () => {
    this.pagoService.setSendMailGestionValue(this.enviarMail);     
  }

  open(): void {

    this.showEnvioMail = this.pagoService.showEnvioMail();
    this.enviarMail = this.pagoService.checkEnvioMail();

    this.element.style.display = 'flex';
    document.body.classList.add('jw-modal-open');
    this.formSubscription = this.opcionesImpresionLocalizadorForm.controls[
      'imprimirTodo'
    ].valueChanges.subscribe(val => {
      if (val) {
        this.marcarTodo();
      } else {
        this.desmarcarTodo();
      }
    });

    this.opcionesImpresionLocalizadorForm.controls['entradas'].enable();
    this.opcionesImpresionLocalizadorForm.controls['etiquetas'].disable();
  }

  close(): void {
    this.printService.setOpcionesDeImpresion(this.opcionesImpresionLocalizadorForm.value);
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
    if(this.formSubscription){this.formSubscription.unsubscribe();}
  }

  public getOpcionesImpresion = () => {
    if (this.enviarMail) {      
      this.close();
      this.openDialog();            
    } else {
      this.aceptarOpcionesEImprimir();
    }         
  }

  aceptarOpcionesEImprimir(mails?: string) {
    this.printService.setOpcionesDeImpresion(this.opcionesImpresionLocalizadorForm.value);

    if (this.printService.opcionesDeImpresionValue.etiquetas) {
     // this.modalService.open('etiquetas');
      //this.router.navigate(['venta', 'entradas']);
      if (this.config.getConfig('COMPRAINICIO')) {
        this.router.navigate([this.config.getConfig('COMPRAINICIO')]);
      } else {
        this.router.navigate(['']);
      }
    }
    this.disabledEntrar = true;
    if (this.printService.opcionesDeImpresionValue.entradas) {
      // this.localizadorService
      //   .reimprimirTickets(this.localizadorService.localizadorValue.ClaveReserva)
      //   .subscribe((infoImpresion) => {
          
          
      //     // 
      //     // 
      //     //<<Yaribel 20210126 unificamos la impresion de tickets en uno solo
      //     // this.printService.reimprimirTickets(
      //     //   this.turnoService.turnoValue.NombreTPV,
      //     //   this.localizadorService.localizadorValue.ClaveReserva,
      //     //   `${this.localizadorService.localizadorValue.FechaHoraEntrada}`,
      //     //   this.localizadorService.localizadorValue.ReservaItems,
      //     //   this.localizadorService.localizadorValue
      //     // );

      //     this.printService.imprimirTicketUnico(
      //       this.turnoService.turnoValue.NombreTPV,
      //       this.turnoService.turnoValue.TpvId,
      //       '',
      //       infoImpresion,
      //       this.localizadorService.localizadorValue.FechaHoraEntrada.split(' ')[1],
      //       this.localizadorService.localizadorValue.ReservaItems,
      //       this.localizadorService.localizadorValue.ClaveReserva,
      //       moment(this.localizadorService.localizadorValue.ReservaHistorial[0].Fecha).format('DD/MM/YYYY'),
      //       this.localizadorService.localizadorValue.ReservaHistorial[0].Hora.slice(0, -3),
      //       this.localizadorService.localizadorValue.ReservaRecintos,
      //       this.localizadorService.localizadorValue.ReservaMotivos,
      //       this.localizadorService.localizadorValue.Localizador,
      //       "0" //this.albaran.TipoImpresion
            
      //     );
      //     // Yaribel 20210126 >> 



      //   });
      if(this.localizadorService.localizadorValue.NumEntradas > 20) {
        const idsGestion = this.localizadorService.localizadorValue.ReservaItems.map(item => item.FarId);

        // Aplana el array y cuenta todos los elementos
        const totalCount = idsGestion.flat().length;
        
        // Genera una secuencia de números desde 1 hasta el total contado
        const positions = Array.from({ length: totalCount }, (_, index) => index + 1);
        
        // Crea las opciones de impresión con la secuencia de posiciones a reimprimir
        const opciones: OpcionesImpresionQR = {
          itemsReimprimir: positions.join(','), // Une las posiciones en una cadena separada por comas
        };
        
        this.pagoService.impresionLocalizador20(this.localizadorService.localizadorValue.Localizador, this.localizadorService.localizadorValue.NumEntradas, opciones)
        .subscribe(
          (res) => {
            // Manejo exitoso de la respuesta
            this.disabledEntrar = false;
            this.router.navigate(['']);
            this.close();
          },
          (error) => {
            this.disabledEntrar = false;
            this.router.navigate(['']);
            this.close();
          }
        );
      } else {
        this.pagoService.impresionLocalizador(this.localizadorService.localizadorValue.Localizador, this.localizadorService.localizadorValue.NumEntradas, mails)
        .subscribe(
          (res) => {
            // Manejo exitoso de la respuesta
            this.disabledEntrar = false;
            this.router.navigate(['']);
            this.close();
          },
          (error) => {
            this.disabledEntrar = false;
            this.router.navigate(['']);
            this.close();
          }
        );
      }
    } else {
      this.disabledEntrar = false;
      this.close();
    }

    if(!mails) {
      this.close();
    }    

  }

  marcarTodo() {
    this.opcionesImpresionLocalizadorForm.controls['entradas'].patchValue(true);
    this.opcionesImpresionLocalizadorForm.controls['etiquetas'].patchValue(true);
  }

  desmarcarTodo() {
    this.opcionesImpresionLocalizadorForm.controls['entradas'].patchValue(false);
    this.opcionesImpresionLocalizadorForm.controls['etiquetas'].patchValue(false);
  }

  disableButtonEnter() {
    return this.disabledEntrar;
  }
}
