import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { FormControlService } from 'src/app/shared/services/form-control.service';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { PagoService } from '../services/pago.service';

export interface DialogMail {
  mails: string;
}

@Component({
  selector: 'dialog-mail',
  templateUrl: 'dialog-mail.component.html',
  styleUrls: ['./dialog-mail.component.scss']
})
export class DialogMail {

  mailsForm: FormGroup;
  showMsgError: boolean = false;
  mailsValidos: boolean;
  cadenaMails: string;

  constructor(
    private fb: FormBuilder,
    private formControlService: FormControlService,
    private translate: TranslateService,
    private pagoService: PagoService,
    public dialogRef: MatDialogRef<DialogMail>,

    @Inject(MAT_DIALOG_DATA) public data: DialogMail) {
      this.mailsForm = this.fb.group({
        mails: ['', Validators.required]
      });
    }
    

  onNoClick() {
    /* SOLUCIÓN CON MENSAJE CONFIRM 
    const msg = this.translate.instant("CONFIRMAR_CERRAR");
    const val = confirm(msg);
    if(val) {   
      this.pagoService.setNoMailValue(true);   
      this.dialogRef.close();
    } else {
      this.pagoService.setNoMailValue(false);
      return false;
    } */
      this.pagoService.setNoMailValue(true);   
      this.dialogRef.close();
  } 

  focusValue(event: any) {
    this.showMsgError = false;
    this.formControlService.setCurrentFormGroup(
      this.mailsForm,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
  }  

  private validMail = (mail: string) : boolean =>  {
    const regex = new RegExp("^[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-z0-9](?:[A-z0-9-]*[A-z0-9])?\\.)+[A-z0-9](?:[A-z0-9-]*[A-z0-9])?$");
    if(regex.test(mail)){
      return true;
    } else {
      return false;
    }
  }

  private buildStringMails = () => {
    if(this.mailsForm.valid) {
      const val = this.mailsForm.value;
      const cadena = val.mails.trim();
      this.mailsValidos = true;
      if(cadena.length > 0) {
        const arrCadena = cadena.split(',');
        let mails: string = '';
        arrCadena.forEach((c) => {  
          c = c.trim(); 
          if(!this.validMail(c)) {
            this.mailsValidos = false;
          } else {
            mails += `emails=${c}&`
          }          
        });
        if(this.mailsValidos) {
          this.cadenaMails = mails.substring(0, mails.length-1);          
        }               
      }
    } else {
      this.showMsgError = true;
    }
  }  

  public saveMails = () => {
    this.buildStringMails();
    if (this.mailsValidos && this.cadenaMails) {
      this.dialogRef.close(this.cadenaMails);    
    } else {
      this.showMsgError = true;
    }   
  }

}