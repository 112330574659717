import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, throwError, from } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
} from 'rxjs/operators';
import { AppConfiguration } from 'src/app/app.configuration';
import { ConfigurationService } from 'src/app/core';
import { environment } from 'src/environments/pedrera/environment.development-pedrera';
import { Entrada } from '../../venta/models/entrada.model';
import { KoreTarifasDisponiblesResponse, PayloadKoreTarifasDisponibles } from '../models/entrada.microservicio.model';

@Injectable({
  providedIn: 'root',
})
export class TiendaService {
  constructor(
    private http: HttpClient,
    private config: AppConfiguration,
    private configuration: ConfigurationService
  ) {}

  /**
   * Obtiene las categorías en formato de árbol jerárquico desde la API.
   * Permite personalizar el orden, tipo de orden y aplicar filtros por nombre y valor.
   *
   * @param orderby - Campo por el cual se desea ordenar (por defecto 'pkId').
   * @param ordertype - Tipo de orden (ASC o DESC, por defecto 'ASC').
   * @param name - (Opcional) Lista de nombres de los filtros a aplicar.
   * @param value - (Opcional) Lista de valores correspondientes a los filtros.
   * @returns Observable que emite el listado de categorías jerárquicas en formato de árbol.
   */
  getCategorias(
    orderby: string = 'pkId',
    ordertype: string = 'ASC',
    name?: string[],
    value?: string[]
  ) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId') || 'null');

    // Verifica si conexionId es válido
    if (!conexionId) {
      console.error('conexionId no está definido o es inválido');
      return throwError(() => new Error('Error: conexionId inválido.'));
    }

    // Construye la URL dinámicamente
    const url = `${this.config.getConfig(
      'API_URL_MS'
    )}/api/Tienda/categoriasjerarquicasarbol`;

    let params = new HttpParams();

    params = params.set('orderby', orderby);
    params = params.set('ordertype', ordertype);

    if (name && value) {
      name.forEach((n, i) => {
        params = params.set('name', n);
        params = params.set('value', value[i]);
      });
    }

    return this.http.get(url, { params, responseType: 'json' }).pipe(
      distinctUntilChanged(),
      shareReplay(1),
      map((res: any) => res.datosResult.listado)
    );
  }

  getEntradas(
    categoriaId?: string,
    botonera?: string,
    barCodeStock?: string,
    texto?: string,
    codigoCliente?: string,
    filtro?: string,
    pagina = '1',
    numeroRegistros?: string,
    tipoElemento?: string
  ): Observable<Entrada[]> {
    return this.createToken().pipe(
      switchMap((token) => {
        const config = this.configuration.appConfig.getValue();
        const conexionId = JSON.parse(localStorage.getItem('conexionId'));

        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          Authorization: `Bearer ${token['__zone_symbol__value']}`,
          "IAC-Connection-String'": this.config.getConfig('chClaveEmpresa'),
        };

        // Construye la URL dinámicamente
        const url = `${this.config.getConfig(
          'API_URL_MS'
        )}/api/Tienda/KoreTarifasDisponibles`;

        const rawParams = {
          conexionId: conexionId.toString(),
          categoriaId,
          botonera,
          barCodeStock,
          texto,
          tpvId: this.configuration.datosTPVPathValue.pkId,
          codigoCliente,
          filtro,
          numeroRegistros,
          tipoElemento,
        };

        const params = Object.entries(rawParams)
          .filter(([_, value]) => value != null)
          .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {});

        return this.http
          .get(url, { headers: headers, params, responseType: 'json' })
          .pipe(distinctUntilChanged())
          .pipe(shareReplay(1))
          .pipe(
            map((res: any) => {
              if (!res || res.datosResult === null) {
                return [];
              }
              return res.datosResult.listadoKoreTarifasDisponibles.map(
                (tarifa: any) => this.transformarATipoEntrada(tarifa)
              );
            })
          );
      })
    );
  }

  getEntradasMicroservicesV2(
    categoriaId: number,
    cliente?,
    tipoElemento = '2',
    texto?
  ): Observable<Entrada[]> {
    const config = this.configuration.appConfig.getValue();
  
    // Construir el payload inicial
    let payload: PayloadKoreTarifasDisponibles = {
      Filtro: texto || '',
      Texto: '',
      TipoElemento: tipoElemento,
      CodigoCliente: cliente ? cliente.Codigo : '0',
      FiltrarPorCliente:
        config.DatosResult.AppConfigJsonConfig.ClienteFiltrarProductosPorCliente || 0,
      CategoriaId: categoriaId.toString(),
      TPVId: this.configuration.datosTPVPathValue.pkId || null,
    };
  
    // Remover CategoriaId del payload si texto tiene un valor
    if (texto) {
      delete payload.CategoriaId;
    }
  
    const URL = `${this.config.getConfig(
      'API_URL_MS'
    )}/api/Tienda/KoreTarifasDisponibles`;
  
    // Crear los parámetros de la URL a partir del payload
    let params = new HttpParams();
  
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }
  
    // Realizar la llamada HTTP
    return this.http
      .get<KoreTarifasDisponiblesResponse>(URL, {
        params,
      })
      .pipe(distinctUntilChanged())
      .pipe(shareReplay(1))
      .pipe(
        map((res: KoreTarifasDisponiblesResponse) => {
          if (res === null || res.datosResult === null) {
            return [];
          }
          return res.datosResult.listadoKoreTarifasDisponibles.map(
            (tarifa: any) => this.transformarATipoEntrada(tarifa)
          );
        })
      );
  }

  createToken() {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
    };
    const bodyToken = {
      usuario: environment.API_TOKEN_USER,
      contrasena: environment.API_TOKEN_PASSWORD,
    };
    return this.http
      .post(`${this.config.getConfig('API_URL_TAQUILLA')}/Token`, bodyToken, {
        headers: headers,
      })
      .pipe(
        map(async (res: any) => {
          return res.token;
        })
      );
  }

  private transformarATipoEntrada(tarifa: any): Entrada {
    return {
      FechaHoraEntrada: null, // Define el valor según tus reglas
      NombreTipo: tarifa.nombreTipo || '',
      Fecha: tarifa.fecha || '',
      id: tarifa.id || null,
      idTarifa: tarifa.idTarifa || '',
      GrupoTarifa: tarifa.grupoTarifa || '',
      noEditable: false, // Ajusta según necesidad
      ItemId: tarifa.itemId || '',
      CategoriaId: Number(tarifa.categoriaId) || 0,
      TipoEntradaId: tarifa.tipoEntradaId || '',
      TipoPromocionId: tarifa.tipoPromocionId || '',
      TipoAbonadoId: tarifa.tipoAbonadoId || '',
      GrupoId: tarifa.grupoId || '',
      GrupoMotivoId: tarifa.grupoMotivoId || '',
      NombrePantalla: tarifa.nombrePantalla || '',
      NombreImpresion: tarifa.nombreImpresion || '',
      NombreDisplay: tarifa.nombreDispay || '',
      Minimo: tarifa.minimo || '0',
      Maximo: tarifa.maximo || '0',
      Orden: tarifa.orden || '0',
      PrecioEnDivisaReferencia: tarifa.precioEnDivisaReferencia || '0',
      PrecioBase: tarifa.precioBase || '0',
      IVA: tarifa.iva || '0',
      Comision: tarifa.comision || '0',
      IvaComision: tarifa.ivaComision || '0',
      ComisionBase: tarifa.comisionBase || '0',
      DesglosarComision: tarifa.desglosarComision || '0',
      recintos: tarifa.recintos || [],
      motivos: [], // Llena según tus necesidades
      extraData: tarifa.argumentosCaracteristica
        ? JSON.parse(tarifa.argumentosCaracteristica)
        : null,
      auditorio:
        Array.isArray(tarifa.recintos) &&
        tarifa.recintos.some((r: any) => r.EsActividad === '1'),
      fechaAuditorio: '',
      horaAuditorio: '',
      idRecintoButaca: '',
      PromocionEntradas: tarifa.promocionEntradas || [],
      TipoProducto: tarifa.tipoProducto || '',
      BarCode: tarifa.barCodeStock || '',
      CaracteristicasEntrada: Object.entries(tarifa.caracteristicas || {}).map(
        ([key, value]) => ({
          clave: key,
          valor: value,
        })
      ),
      Cantidad: tarifa.cantidadCompra || '0',
      PosicionesVenta: tarifa.posicionesVenta || [],
      CuentaVisitante: tarifa.cuentaVisitante || '0',
      FarId: [],
      ImprimirTicket: tarifa.imprimirTicket || '0',
      ListaButacas: [],
      Modificado: false,
      NumOrdenPromos: [],
      NumOrdenPromosConEntradas: [],
      cantidad: tarifa.cantidadCompra || '0',
      PrecioTotal: tarifa.precioBase || '0',
      ControlAforoExterno:
        Array.isArray(tarifa.recintos) && tarifa.recintos.length > 0
          ? tarifa.recintos[0].ControlAforoExterno || ''
          : '',
      FechaHoraAccesoLocalizadorButaca: null,
      DNIAbonadosExt: null,
      desdeAsociado: false,
      codigoAsociado: null,
      ImporteDto: tarifa.importeDto || '',
      PrecioBaseConDto: tarifa.precioBaseConDto || '',
    };
  }
}
