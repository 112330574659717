export const environment = {
  console: true,
  devUser : '',
  devPassword: '',
  ADMIN_AGENDA_URL: "https://admin.museocr.iacpospre.com/admin/Agenda.aspx",
  ADMIN_INDEX_URL: "https://admin.museocr.iacpospre.com/MenuOperacionesEspeciales.aspx",
  API_URL: "https://apipre.apiacpos.com/2_3_0_bridge/",
  API_URL_TAQUILLA: 'https://apipre.apiacpos.com/WebAPIImpresionTaquilla',
  CODIGO_APLICACION: "PVKORE",
  ID_APLICACION: "1",
  TPV_PATH: "FAPA-RRHH13",
  NUMSERIE:"TAQU$A$N0QBO3AQ0$A$ILLA$A$CVW1R9537",
  chClaveEmpresa: "MUSEOCR",
  chEntornoConexion: "PREPRODUCCION",
  chNombreProyecto: "TAQUILLA",
  chOrigenConexion: "TAQUILLA",
  production: false,
  svgFolder: "development",
  API_TOKEN_USER: "admin@admin.com",
  API_TOKEN_PASSWORD: "IACPOS.2023a+",
};
