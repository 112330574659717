export const environment = {
  console: true,
  devUser : '',
  devPassword: '',
  ADMIN_AGENDA_URL: "https://admin.lapedrera.iacposdes.com/admin/Agenda.aspx",
  ADMIN_INDEX_URL: "https://admin.lapedrera.iacposdes.com/admin/MenuOperacionesEspeciales.aspx",
  API_URL: "https://apides.apiacpos.com/api_bridge_pedrera/",
  API_URL_TAQUILLA: 'https://apides.apiacpos.com/WebAPIImpresionTaquilla',
  CODIGO_APLICACION: "PVKORE",
  ID_APLICACION: "1",
  TPV_PATH: "SCLPC101",
  NUMSERIE:"TAQU$A$N0QBO3AQ0$A$ILLA$A$CVW1R9537",
  chClaveEmpresa: "PEDRERA",
  chEntornoConexion: "DESARROLLO",
  chNombreProyecto: "TAQUILLA",
  chOrigenConexion: "TAQUILLA",
  production: false,
  svgFolder: "development",
  API_TOKEN_USER: "admin@admin.com",
  API_TOKEN_PASSWORD: "IACPOS.2023a+",
  API_URL_MS: "https://apides.apiacpos.com/WebAPIImpresionTaquilla"
};
