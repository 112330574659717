import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Albaran } from '../../models/albaran.model';
import { AlbaranService } from '../../services/albaran.service';
import { Observable } from 'rxjs';
import { PrintService } from 'src/app/shared/services/print.service';
import { FacturaService } from 'src/app/shared/services/factura.service';
import { PagerService } from 'src/app/shared/services/pager.service';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FilterService } from 'src/app/shared/services/filter.service';
import { OpcionesImpresionQR, PagoService } from 'src/app/modules/venta/services/pago.service';
import { AlbaranTicketProducto } from 'src/app/modules/gestionar-venta/models/albaran.model';
@Component({
  selector: 'ticketing-imprimir-venta',
  templateUrl: './imprimir-venta.component.html',
  styleUrls: ['./imprimir-venta.component.scss']
})
export class ImprimirVentaComponent implements OnInit {
  albaran$: Observable<Albaran>;
  disabledEntrar = false;
  albaran: Albaran;
  arr = [];
  selectedItems = [];
  ticketsProductosArr = [];
  @ViewChild('checkAllSwitch') checkAllSwitch: ElementRef;
  checkboxState: { [key: string]: boolean } = {};

  pager: any = {
    pages: []
  };
  pagedItems: any[];

  busquedaForm: FormGroup;
  valoresImpresion: any;

  constructor(
    private albaranService: AlbaranService,
    private facturaService: FacturaService,
    private printService: PrintService,
    private formControlService: FormControlService,
    private filterService: FilterService,
    private formBuilder: FormBuilder,
    private pagerService: PagerService,
    private pagoService: PagoService
  ) {
  }

  ngOnInit() {
    this.busquedaForm = this.formBuilder.group({
      query: ''
    });
    this.valoresImpresion = this.printService.opcionesDeImpresionValue;
    this.albaran = this.albaranService.albaranValue;
    let test = this.albaran.NumAlbaran || this.albaran.NumVenta;
    this.albaranService.getDatosAlbaranTicketsProductos(test)
    .subscribe(res => {
      // Aquí se ejecutará cuando se reciba la respuesta de la llamada HTTP
      this.ticketsProductosArr = res.ListadoAlbaranTicketsProductos;
      this.arr = res.ListadoAlbaranTicketsProductos;
      const listaCompleta = this.arr;
      this.ticketsProductosArr.forEach(ticket => {
        this.checkboxState[ticket.ProductoId] = false; // Por defecto, ninguno está seleccionado
      });
      this.busquedaForm.valueChanges.subscribe(val => {
        if (val.query === '') {
          this.arr = listaCompleta;
        } else {
          this.arr = [];
          listaCompleta.forEach(item => {
            let querySplit = val.query.split(',');
            querySplit.forEach(queryElement => {
              if (queryElement === item.TicketsGestion.toString()) {
                this.arr.push(item);
              }
            });
          });
        }
        this.setPage(1);
      });
      this.setPage(1);
      this.selectAll();
    });
  }
  disableButtonEnter() {
    return this.disabledEntrar;
  }

  checkEntrada(event, ticket: AlbaranTicketProducto) {
    if (event.target.checked) { // Verificar si el checkbox está marcado
      this.selectedItems.push(ticket); // Agregar el ticket seleccionado
    } else {
      this.selectedItems = this.selectedItems.filter(e => e.TicketsRango !== ticket.TicketsRango); // Eliminar el ticket deseleccionado
    }
    // Verificar si todos los elementos están seleccionados para marcar o desmarcar el checkbox "Imprimir todas"
    this.checkAllSwitch.nativeElement.checked = (this.selectedItems.length === this.arr.length);
  }

  isChecked(ticket) {
    return this.selectedItems.find(e => e.TicketsRango === ticket.TicketsRango);
  }

  checkAllEntradas(event) {
    if (event.target.checked) {
      this.selectedItems = [...this.arr]; // Marcar todos los checkboxes
    } else {
      this.selectedItems = []; // Desmarcar todos los checkboxes
    }
  }

  private selectAll() {
    this.arr.forEach((e, i) => {
      if (!this.isChecked(e)) { //Yaribel 20210318
        this.selectedItems.push(e);
      }
    });
  }

  private unselectAll() {
    this.selectedItems = [];
  }

  groupBy(xs, f) {
    return xs.reduce(
      (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r),
      {}
    );
  }

  /* private generateItems(tickets, items) {
     return tickets.map(ticket => {
       const datosEntrada = items.find(i => i.ItemId === ticket.ItemId);
       datosEntrada.PrecioEnDivisaReferencia = datosEntrada.PrecioUnidad;
       datosEntrada.idTarifa = datosEntrada.ItemId;
       const yaDevuelto = ticket.Devuelta === '0' ? false : true;
       const imprimir = ticket.ImprimirTicket === 'True' ? true : false;
       const nTicket =
         this.albaran.TipoImpresion === '01'
           ? +ticket.OrdenCjta
           : +ticket.NumTicket;
       return {
         ...ticket,
         imprimir: imprimir,
         yaDevuelto: yaDevuelto,
         id: nTicket,
         itemInfo: datosEntrada
       };
     });
   }*/

  private generateItems(tickets, albaranes) {
    // 
    const ticketsAgrupados = this.groupBy(tickets, c =>
      c.NumOrdenPromo !== '' ? c.NumOrdenPromo : c.RegistroTablaId
    );
    // 
    // 
    const respuesta = [];
    const numOrdenPromoId = [];
    for (const [key, ticket] of Object.entries(ticketsAgrupados)) {
      // 
      // 
      if (Object.entries(ticket).length > 1) {
        let contador = 0;
        while (contador <= 1) {

          if (ticket[contador].TipoProducto === '1') {
            const datosEntrada = albaranes.find(i => {
              return i.ItemId === ticket[contador].ItemId && i.TipoProducto == 1 && i.CodigoPromocional == ticket[contador].CodigoPromocional;
            });
            // 
            datosEntrada.PrecioEnDivisaReferencia = datosEntrada.PrecioUnidad;
            datosEntrada.idTarifa = datosEntrada.ItemId;
            datosEntrada.TipoEntradaId = datosEntrada.ItemId;
            const yaDevuelto = ticket[contador].Devuelta === '0' ? false : true;
            const imprimir = (ticket[contador].ImprimirTicket === 'True' || ticket[contador].ImprimirTicket === '1') ? true : false;
            respuesta.push({
              tickets: ticket,
              imprimir: imprimir,
              yaDevuelto: yaDevuelto,
              id: +ticket[contador].NumTicket,
              numTickets: +ticket[contador].NumTicket,
              itemInfo: datosEntrada,
              OrdenCjta: +ticket[contador].OrdenCjta

            });
          } else if (ticket[contador].TipoProducto === '2') {
            // 

            if (!numOrdenPromoId.includes(ticket[contador].NumOrdenPromo)) {
              const datosEntrada = albaranes.find(item => {
                return item.ItemId === ticket[contador].TipoPromocionId && item.TipoProducto == 2 && item.CodigoPromocional == ticket[contador].CodigoPromocional;
              });
              // 
              datosEntrada.PrecioEnDivisaReferencia = datosEntrada.PrecioUnidad;
              datosEntrada.idTarifa = datosEntrada.ItemId;
              datosEntrada.TipoPromocionId = datosEntrada.ItemId;
              const yaDevuelto = ticket[contador].Devuelta === '0' ? false : true;
              //const imprimir =  (ticket[contador].ImprimirTicket === 'True' || ticket[contador].ImprimirTicket === '1') ? true : false;             
              let imprimir = false;
              for (let ix = 0; ix < Object.entries(ticket).length; ix++) {
                if (ticket[ix].ImprimirTicket === 'True' || ticket[ix].ImprimirTicket === '1') {
                  imprimir = true;
                }
              }
              // 
              // 
              numOrdenPromoId.push(ticket[contador].NumOrdenPromo);
              const promocionEntradas = [];
              datosEntrada.PromocionEntradas.forEach(entrada => {
                const tempObject = Object.assign({}, entrada, {
                  NumOrdenPromo: ticket[contador].NumOrdenPromo
                });
                promocionEntradas.push(tempObject);
              });
              // 
              const temp = Object.assign({}, datosEntrada, {
                PromocionEntradas: promocionEntradas
              });
              let numTickets = '';
              for (const [k, item] of Object.entries(ticket)) {
                numTickets = '' + numTickets + ', ' + item.NumTicket;
              }
              numTickets = numTickets.substr(1);
              // 
              respuesta.push({
                tickets: ticket,
                imprimir: imprimir,
                yaDevuelto: yaDevuelto,
                id: +ticket[contador].NumTicket,
                numTickets: numTickets,
                itemInfo: temp,
                OrdenCjta: +ticket[contador].OrdenCjta
              });
            }
          }
          contador++;
        }
      } else {
        if (ticket[0].TipoProducto === '1') {
          const datosEntrada = albaranes.find(i => {
            return i.ItemId === ticket[0].ItemId && i.TipoProducto == 1 && i.CodigoPromocional == ticket[0].CodigoPromocional;
          });
          // 
          datosEntrada.PrecioEnDivisaReferencia = datosEntrada.PrecioUnidad;
          datosEntrada.idTarifa = datosEntrada.ItemId;
          datosEntrada.TipoEntradaId = datosEntrada.ItemId;
          const yaDevuelto = ticket[0].Devuelta === '0' ? false : true;
          const imprimir = (ticket[0].ImprimirTicket === 'True' || ticket[0].ImprimirTicket === '1') ? true : false;
          respuesta.push({
            tickets: ticket,
            imprimir: imprimir,
            yaDevuelto: yaDevuelto,
            id: +ticket[0].NumTicket,
            numTickets: +ticket[0].NumTicket,
            itemInfo: datosEntrada,
            OrdenCjta: +ticket[0].OrdenCjta
          });
        } else if (ticket[0].TipoProducto === '2') {
          // 

          if (!numOrdenPromoId.includes(ticket[0].NumOrdenPromo)) {
            const datosEntrada = albaranes.find(item => {
              //return item.ItemId === ticket[0].ItemId && item.TipoProducto == 2;
              //return item.ItemId === ticket[0].TipoPromocionId && item.TipoProducto == 2; //Yaribel 20201127 Cambiamos esto para que evalue por item
              return item.ItemId === ticket[0].TipoPromocionId && item.TipoProducto == 2 && item.CodigoPromocional == ticket[0].CodigoPromocional; //Yaribel 20210607 Añadimos codigo promocional
            });
            // 
            datosEntrada.PrecioEnDivisaReferencia = datosEntrada.PrecioUnidad;
            datosEntrada.idTarifa = datosEntrada.ItemId;
            datosEntrada.TipoPromocionId = datosEntrada.ItemId;
            const yaDevuelto = ticket[0].Devuelta === '0' ? false : true;
            // const imprimir = (ticket[0].ImprimirTicket === 'True' || ticket[0].ImprimirTicket === '1') ? true : false;
            let imprimir = false;
            for (let ix = 0; ix < Object.entries(ticket).length; ix++) {
              if (ticket[ix].ImprimirTicket === 'True' || ticket[ix].ImprimirTicket === '1') {
                imprimir = true;
              }
            }
            // 
            // 
            numOrdenPromoId.push(ticket[0].NumOrdenPromo);
            const promocionEntradas = [];
            datosEntrada.PromocionEntradas.forEach(entrada => {
              const tempObject = Object.assign({}, entrada, {
                NumOrdenPromo: ticket[0].NumOrdenPromo
              });
              promocionEntradas.push(tempObject);
            });
            // 
            const temp = Object.assign({}, datosEntrada, {
              PromocionEntradas: promocionEntradas
            });
            let numTickets = '';
            for (const [k, item] of Object.entries(ticket)) {
              numTickets = '' + numTickets + ', ' + item.NumTicket;
            }
            numTickets = numTickets.substr(1);
            // 
            respuesta.push({
              tickets: ticket,
              imprimir: imprimir,
              yaDevuelto: yaDevuelto,
              id: +ticket[0].NumTicket,
              numTickets: numTickets,
              itemInfo: temp,
              OrdenCjta: +ticket[0].OrdenCjta
            });
          }
        }
      }
    }
    // 
    return respuesta;
  }

  protected setPage(page: number): void {
    this.pager = this.pagerService.getPager(this.arr.length, page, 10);
    this.pagedItems = this.arr.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
    this.pager.pages = this.pager.pages.filter(
      num => num <= this.pager.totalPages
    );

    while (this.pager.pages.length < 6 && this.pager.pages[0] > 1) {
      this.pager.pages.unshift(this.pager.pages[0] - 1);
    }
  }

  async imprimir() {
    this.disabledEntrar = true;
    let mails: string;
    if(this.pagoService.sendMailGestionValue && this.pagoService.stringMailGestionValue) {
      mails = this.pagoService.stringMailGestionValue;
    }

    if (this.printService.opcionesDeImpresionValue.entradas) {
      const idsSeleccionados = [];
      this.selectedItems.forEach(item => {
          const cadena = item.TicketsGestion.toString();
          if (cadena.includes(',')) {
            const arraySelected = cadena.split(',');
            arraySelected.forEach(element => {
              const order = Number.parseInt(element, 10);
              idsSeleccionados.push(order);
            });
          } else {
            const order = Number.parseInt(cadena, 10);
            idsSeleccionados.push(order);
          }
      });
      const opciones: OpcionesImpresionQR = {
        itemsReimprimir: idsSeleccionados.join(','), // Convertir los IDs a una cadena separada por comas
        tpvId: this.albaran.TPVId
      };
      if(idsSeleccionados.length > 20){
        this.pagoService.impresionQR20(this.albaran.NumAlbaran, idsSeleccionados.length, opciones, mails)
        .subscribe(
          {
            next: () => {
              this.disabledEntrar = false;
            },
            error: (error) => {
              this.disabledEntrar = false;
            }
          }
        )
      } else {
        this.pagoService.impresionQR(this.albaran.NumAlbaran, idsSeleccionados.length, opciones, mails)
        .subscribe(
          {
            next: () => {
              this.disabledEntrar = false;
            },
            error: (error) => {
              this.disabledEntrar = false;
            }
          }
        )
      }
    }
  }

  focusValue(event) {
    this.formControlService.setCurrentFormGroup(
      this.busquedaForm,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
  }

  getNumTicket(item) {
    if (+item.OrdenCjta > 0) {
      return item.OrdenCjta;
    }
    return item.TicketsGestion;
  }
}
